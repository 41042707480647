import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import RadioButton from 'primevue/radiobutton';
import axios from 'axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import FormBody from './components/Form/Body.vue';

import InputText from './components/Form/InputText.vue';
import InputMask from './components/Form/InputMask.vue';
import InputPassword from './components/Form/InputPassword.vue';
import InputCheckbox from './components/Form/InputCheckbox.vue';
import InputDropdown from './components/Form/InputDropdown.vue';
import InputNumber from './components/Form/InputNumber.vue';

import AppPageLayout from './layouts/AppPageLayout.vue';

import UiButton from './components/Ui/Button.vue';

import './assets/scss/index.scss';

axios.defaults.baseURL = 'https://betasensovent.harmann.pl/api';
if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
	axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('auth._token')}` };
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://pde.4multimedia.pl';
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';

let activity = 'ending';

axios.interceptors.request.use((config) => {
	document.body.classList.add('loading-indicator');
	activity = 'loading';
	return config;
}, (error) => {
	document.body.classList.remove('loading-indicator');
	return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
	activity = 'waiting';
	setTimeout(() => {
		if (activity === 'waiting') {
			activity = 'ending';
			document.body.classList.remove('loading-indicator');
		}
	}, 300);
	return response;
}, (error) => {
	console.log(error);
	document.body.classList.remove('loading-indicator');
	return Promise.reject(error);
});

const app = createApp(App);
const pinia = createPinia();

app.use(PrimeVue);
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.use(pinia);
app.use(router);

app.component('FormBody', FormBody);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('InputPassword', InputPassword);
app.component('InputCheckbox', InputCheckbox);
app.component('InputDropdown', InputDropdown);
app.component('InputNumber', InputNumber);
app.component('UiButton', UiButton);
app.component('AppPageLayout', AppPageLayout);
app.component('RadioButton', RadioButton);

app.mount('#app');
