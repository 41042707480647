<template>
	<div class="form__field mb-16" :class="{hasSufix: hasSlot('sufix'), hasPrefix: hasSlot('prefix')}">
		<div v-if="hasSlot('prefix')" class="form__field__prefix"><slot name="prefix"></slot></div>

		<template v-if="layout == 'float'">
			<span class="p-float-label">
                <slot />
				<!-- eslint-disable-next-line -->
                <label v-if="label">{{ label }} <span class="require" v-if="require">*</span></label>
            </span>
        </template>
        <template v-else>
			<!-- eslint-disable-next-line -->
            <label v-if="label">{{ label }} <span class="require" v-if="require">*</span></label>
            <slot />
        </template>
		<div v-if="hasSlot('sufix')" class="form__field__sufix"><slot name="sufix"></slot></div>
		<div v-if="hasSlot('textprepend')" class="app__form__field__textprepend"><slot name="textprepend"></slot></div>
		<input-error :errors="error" />
	</div>
</template>

<script setup>
import { useSlots } from 'vue';
import InputError from './Error.vue';

const slots = useSlots();
const hasSlot = (name) => !!slots[name];

defineProps({
	label: String,
	error: Object,
	require: Boolean,
	layout: {
		type: String,
		default: 'normal',
	},
});
</script>

<script>
/* eslint-disable */
export default {
	name: 'FormField',
};
</script>
