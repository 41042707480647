<template>
	<ul v-if="errors" class="form__error">
		<li v-for="error, index in errors" :key="index">{{ error }}</li>
	</ul>
</template>

<script setup>
defineProps({
	errors: Object,
});
</script>

<script>
export default {
	name: 'InputError',
};
</script>
