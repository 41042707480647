<template>
    <footer>
        <div class="container">
            <div class="d-flex align-items-center">
                <div>
                    <img src="/image/ui/logo_white.png" alt="">
                </div>
                <ul>
                    <li>Harmann Polska Sp. z o.o.</li>
                    <li>Kokotów 703, 32-002 Kokotów</li>
                    <li>tel. 12 650 20 30</li>
                    <li>biuro@harmann.pl</li>
                    <li>www.harmann.pl</li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
	name: 'UiFooter',
};
</script>
