<template>
	<form-field :label="label" :error="error" :require="require">
		<template v-if="hasSlots('prefix')" #prefix><slot name="prefix"></slot></template>
		<template v-if="hasSlots('sufix')" #sufix><slot name="sufix"></slot></template>
		<template #option><slot name="option"></slot></template>
		<Dropdown v-bind="$attrs" optionLabel="name" optionValue="id" />
	</form-field>
</template>

<script setup>
import { useSlots } from 'vue';
import FormField from '@/components/Form/Field.vue';
import Dropdown from 'primevue/dropdown';

const slots = useSlots();
const hasSlots = (name) => !!slots[name];

defineProps({
	label: String,
	error: Object,
	require: {
		type: Boolean,
		default: false,
	},
});
</script>
