<template>
    <div class="load">
        <ProgressSpinner />
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
	name: 'UiLoader',
	components: {
		ProgressSpinner,
	},
};
</script>
