import { defineStore } from 'pinia';

export const useUserStore = defineStore('store.user', {
	state: () => ({
		authenticated: false,
		user: {},
		token: '',
	}),
	getters: {

	},
	actions: {
		setToken(token) {
			this.token = token;
		},
		setUser(user) {
			this.user = user;
		},
		setAuthenticated(value) {
			this.authenticated = value;
		},
	},
});
