import { useUserStore } from '@/store/useUserStore';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '',
		redirect: '/pl/auth/login',
	},
	{
		path: '/pl',
		name: 'home',
		component: () => import('../layouts/AppLayout.vue'),
		children: [
			{
				path: 'auth',
				component: () => import('../layouts/AuthLayout.vue'),
				children: [
					{
						path: 'login',
						name: 'authLogin',
						component: () => import('../views/Auth/LoginView.vue'),
					},
					{
						path: 'register',
						name: 'authRegister',
						component: () => import('../views/Auth/RegisterView.vue'),
					},
					{
						path: 'reset',
						name: 'authReset',
						component: () => import('../views/Auth/ResetView.vue'),
					},
					{
						path: 'password/:hash',
						name: 'authPassword',
						component: () => import('../views/Auth/PasswordView.vue'),
					},
				],
			},
			{
				path: '/configuration',
				name: 'configuration',
				component: () => import('../views/App/Configuration/IndexView.vue'),
				meta: {
					requiresAuth: true,
					roleAccount: [1, 2],
				},
			},
			{
				path: '/project',
				component: () => import('../views/App/Project/DefaultView.vue'),
				meta: {
					requiresAuth: true,
					roleAccount: [1, 2],
				},
				children: [
					{
						path: '',
						name: 'project',
						component: () => import('../views/App/Project/IndexView.vue'),
					},
					{
						path: '/project/create',
						name: 'projectCreate',
						component: () => import('../views/App/Project/CreateView.vue'),
					},
					{
						path: '/project/:hash',
						name: 'projectUpdate',
						component: () => import('../views/App/Project/UpdateView.vue'),
					},
				],
			},
			{
				path: '/chart',
				name: 'chart',
				component: () => import('../views/App/Chart/IndexView.vue'),
				meta: {
					requiresAuth: true,
					roleAccount: [1, 2],
				},
			},
			{
				path: '/schema',
				name: 'schema',
				component: () => import('../views/App/Schema/IndexView.vue'),
				meta: {
					requiresAuth: true,
					roleAccount: [1, 2],
				},
			},
			{
				path: '/annual-calculator',
				name: 'annual-calculator',
				component: () => import('../views/App/AnnualCalculator/IndexView.vue'),
				meta: {
					requiresAuth: true,
					roleAccount: [1, 2],
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

function checkAccess(to) {
	const userStore = useUserStore();
	const { userProxy, userProxyId, user } = storeToRefs(userStore);

	let userInfo = user.value;
	if (userProxyId.value != null) {
		userInfo = userProxy.value;
	}

	const roleAccount = to.matched.some((record) => record.meta.roleAccount);
	const roleAdmin = to.matched.some((record) => record.meta.roleAdmin);

	if (roleAccount && userInfo.role.includes(1)) {
		return true;
	}
	if (roleAdmin && userInfo.role.includes(2)) {
		return true;
	}
	if (roleAccount || roleAdmin) {
		return false;
	}
	return true;
}

router.beforeEach(async (to, from, next) => {
	if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
		const userStore = useUserStore();
		const { setToken, setUser, setAuthenticated } = userStore;
		const { authenticated } = storeToRefs(userStore);

		if (authenticated.value === false) {
			try {
				const request = await axios.get('/user');
				const { data } = request;

				setToken(localStorage.getItem('auth._token'));
				setUser(data);
				setAuthenticated(true);

				if (to.matched.some((record) => record.meta.isAuth)) {
					window.scrollTo(0, 0);
					next({ path: '/' });
				}

				window.scrollTo(0, 0);
				if (checkAccess(to)) {
					next();
				} else {
					next({ path: '/access-blocked' });
				}
			} catch (e) {
				localStorage.removeItem('auth._token');
				window.location.reload();
			}
		} else {
			window.scrollTo(0, 0);
			if (checkAccess(to)) {
				next();
			} else {
				next({ path: '/access-blocked' });
			}
		}
	} else if (to.matched.some((record) => record.meta.requiresAuth)) {
		window.scrollTo(0, 0);
		next({
			path: '/pl/auth/login',
			params: { nextUrl: to.fullPath },
		});
	}

	window.scrollTo(0, 0);
	next();
});

export default router;
