import axios from 'axios';
import { ref } from 'vue';

const formForm = ref({});
const formItems = ref([]);
const fields = ref([]);

const actionValue = ref('index');

export function useForm() {
	const onLoadFormItems = async () => {
		try {
			const request = await axios.get('setting/form');
			const { data } = request;
			formItems.value = data;
		} catch (e) {
			console.error();
		}
	};

	const onLoadFields = async (hash) => {
		try {
			const request = await axios.get(`setting/form/${hash}`);
			const { data } = request;
			fields.value = data.fields;
		} catch (e) {
			console.error(e);
		}
	};

	const onSetAction = (actionType) => {
		actionValue.value = actionType;
	};

	return {
		onLoadFormItems,
		onLoadFields,
		onSetAction,
		formItems,
		formForm,
		fields,
		actionValue,
	};
}
