<template>
    <header>
        <div class="container">
            <div class="d-flex justify-content-between">
                <div class="logo">
                    <img src="/image/ui/logo.png" alt="">
                    <h1> Stałociśnieniowy <b>system wentylacji mechanicznej</b></h1>
                </div>

                <div class="mobile-nav">
                    <router-link to=""><i aria-hidden="true" class="fa fa-align-justify"></i></router-link>
                </div>
                <div class="nav d-flex justify-content-between flex-column">
                    <nav class="account">
                        <ul>
                            <template v-if="authenticated">
                                <li><router-link to="/setting/account">Moje konto</router-link></li>
                                <li><a href="javascript:;" @click="logout" class="">Wyloguj się</a></li>
                            </template>
                            <template v-else>
                                <li><router-link :to="{name: 'authLogin'}">Logowanie</router-link></li>
                                <li><router-link :to="{name: 'authRegister'}" class="">Rejestracja</router-link></li>
                            </template>
                        </ul>
                    </nav>
                    <nav class="main">
                        <ul>
                            <li :class="{active: tab == 'project'}"><router-link :to="{name: 'project'}" class="router-link-active">Projekty</router-link></li>
                            <li :class="{active: tab == 'configuration'}"><router-link to="/configuration" class="">Konfiguracja</router-link></li>
                            <li :class="{active: tab == 'chart'}"><router-link to="/chart" class="">Zestawienie</router-link></li>
                            <li :class="{active: tab == 'schema'}"><router-link to="/schema" class="">Schematy</router-link></li>
                            <li :class="{active: tab == 'annual-calculator'}"><router-link to="/annual-calculator" class="">Kalkulator roczny</router-link></li>
                            <li :class="{active: tab == 'admin'}" v-if="hasAdminRole"><router-link to="/admin/user" class="">Administracja</router-link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useUserStore } from '@/store/useUserStore';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { authenticated } = storeToRefs(userStore);
</script>

<script>
export default {
	name: 'UiHeader',
};
</script>
