<template>
	<form-field :label="label" :error="error" :require="require">
		<template v-if="hasSlots('prefix')" #prefix><slot name="prefix"></slot></template>
		<template v-if="hasSlots('sufix')" #sufix><slot name="sufix"></slot></template>
		<input-text v-bind="$attrs" :class="{'p-invalid' : error }" />
		<slot name="textprepend"></slot>
	</form-field>
</template>

<script setup>
import { useSlots } from 'vue';
import FormField from '@/components/Form/Field.vue';
import InputText from 'primevue/inputtext';

const slots = useSlots();
const hasSlots = (name) => !!slots[name];

defineProps({
	label: String,
	error: Object,
	require: {
		type: Boolean,
		default: false,
	},
});
</script>
